<template lang="pug">
  .chart
    i.spinner.center-vertical-relative(v-if="loading && !error")
    span.bg-danger(v-if="error") Error loading the chart
    div(v-show="!loading")
      h6.margin-0-top.margin-0-bottom.color-gray-800.padding-10-bottom {{ chartTitle }}
      canvas(ref="chart")
      a.underline(:href="csvUrl") {{ downloadLabel }}
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto'
import currency from 'currency.js'

const configFunctions = {
  lineChartConfig: function (data, dataType) {
    return {
      data: data,
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: 'rgb(0,0,0)'
            }
          },
          tooltip: {
            callbacks: {
              label (context) {
                let label = context.dataset.label || ''

                if (label) {
                  label += ': '
                }
                if (context.parsed.y !== null) {
                  if (dataType == 'donations') {
                    label += currency(context.parsed.y).format()
                  } else if (dataType == 'volunteering') {
                    label += currency(context.parsed.y, { symbol: '' }).format() + ' hours'
                  } else if (dataType == 'participation') {
                    label += currency(context.parsed.y, { symbol: '', precision: 0 }).format() + ' employees'
                  } else {
                    label += context.parsed.y
                  }
                }
                return label
              }
            }
          }
        }
      }
    }
  },
  barChartConfig: function (data) {
    return {
      type: 'bar',
      data: data,
      options: {
        backgroundColor: [
          '#1873DB',
          '#f5bc32',
          '#43b08b',
          '#cc6a99'
        ],
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: 'rgb(0,0,0)'
            }
          },
          tooltip: {
            callbacks: {
              title (tooltipItems) {
                let label = ''
                let year = ''

                  tooltipItems.forEach(function (tooltipItem) {
                    label = tooltipItem.label
                    year = tooltipItem.dataset.year
                  })
                  return label + ' ' + year
                },
                label (context) {
                  let label = context.dataset.label || ''

                  if (label) {
                    label += ': '
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y + '%'
                  }
                  return label
                }
              }
            }
          }
        }
      }
  },
  groupedBarChartConfig: function (data) {
    return {
      type: 'bar',
      data: data,
      options: {
        backgroundColor: [
          '#1873DB',
          '#f5bc32',
          '#43b08b',
          '#cc6a99'
        ],
        plugins: {
          afterDatasetsDraw (context, easing) {
            const ctx = context.chart.ctx
            context.data.datasets.forEach(function (dataset) {
              for (let i = 0; i < dataset.data.length; i++) {
                if (dataset.data[i] !== 0) {
                  const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model
                  const textY = model.y + (dataset.type === 'line' ? -3 : 15)

                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily)
                    ctx.textAlign = 'start'
                    ctx.textBaseline = 'middle'
                    ctx.fillStyle = dataset.type === 'line' ? 'black' : 'black'
                    ctx.save()
                    ctx.translate(model.x, textY - 15)
                    ctx.rotate(4.7)
                    ctx.fillText(dataset.data[i], 0, 0)
                    ctx.restore()
                  }
                }
              })
            },
            tooltip: {
              callbacks: {
                title (tooltipItems) {
                  let label = ''
                  let stack = ''

                  tooltipItems.forEach(function (tooltipItem) {
                    label = tooltipItem.label
                    stack = tooltipItem.dataset.stack
                  })
                  return label + ' ' + stack
                },
                label (context) {
                  let label = context.dataset.label || ''

                  if (label) {
                    label += ': '
                  }
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y * 1000)
                  } else {

                  }
                  return label
                },
                afterTitle (tooltipItems) {
                  function findSum (obj) {
                    let sum = 0
                    for (const property in obj) {
                      sum += obj[property]
                    }
                    return sum
                  }

                  const object = tooltipItems[0].parsed._stacks.y
                  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(findSum(object) * 1000) + ' Total'
                }
              }
            },
            legend: {
              position: 'bottom',
              labels: {
                color: 'rgb(0,0,0)'
              }
            }
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      }
  }
}
export default {
  props: {
    chartTitle: { type: String, required: true },
    chartType: { type: String, required: true },
    dataType: { type: String, required: true },
    csvUrl: { type: String, required: true },
    downloadLabel: { type: String, required: true },
    jsonEndpointUrl: { type: String, required: true }
  },

  data () {
    return { error: false, loading: true }
  },

  created () {
    axios.get(this.jsonEndpointUrl, { headers: { Accept: 'application/json' } }).catch((error) => {
      this.error = true
    }).then((response) => {
      /* eslint-disable no-new */
      new Chart(this.$refs.chart, configFunctions[`${this.chartType}ChartConfig`](response.data, this.dataType))
      this.loading = false
    })
  }
}
</script>
