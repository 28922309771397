import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'label', 'preLabel', 'start', 'end', 'shortcuts']

  connect () {
    this.setPreDateLabel()
    this.setDateLabel()
  }

  change () {
    this.setPreDateLabel()
    this.setDateLabel()
  }

  selected (event) {
    if (this.shortcutsTarget.selectedOptions[0].text !== 'Custom') {
      const [start, end] = event.target.value.split(' ')
      this.startTarget.value = start
      this.endTarget.value = end
    }
  }

  clickItem (event) {
    event.stopPropagation()
  }

  compare (event) {
    this.formTarget.submit()
  }

  edited (event) {
    this.shortcutsTarget.value = 'custom'
    this.startTarget.setAttribute('max', this.endTarget.value)
    this.endTarget.setAttribute('min', this.startTarget.value)

    this.setPreDateLabel()
    this.setDateLabel()
  }

  setPreDateLabel () {
    if (this.shortcutsTarget.selectedOptions[0].text === 'Custom') {
      this.preLabelTarget.innerHTML = ' from'
    } else if (this.shortcutsTarget.selectedOptions[0].text === 'Year to date') {
      this.preLabelTarget.innerHTML = ''
    } else {
      this.preLabelTarget.innerHTML = ' from the'
    }
  }

  setDateLabel () {
    if (this.shortcutsTarget.selectedOptions[0].text === 'Custom') {
      this.labelTarget.innerHTML = `${this.startTarget.value} - ${this.endTarget.value}`
    } else {
      this.labelTarget.innerHTML = this.shortcutsTarget.selectedOptions[0].text.toLowerCase()
    }
  }
}
