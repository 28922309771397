import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['skillBox'];

  updateUserSkill (event) {
    event.preventDefault()
    const checked = this.skillBoxTargets.filter(status => status.checked)
    const url = '/users/update_skill'
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token
      },
      body: JSON.stringify({ skill_ids: checked.map((input) => { return input.value }) })
    })
    .then(response => response.json())
    .catch(error => console.error('Error:', error))
  }
}
