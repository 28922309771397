export default function initDirectUpload () {
  document.addEventListener('click', event => {
    if (event.target.matches('.js-direct-upload')) {
      event.preventDefault()
      const input = document.querySelector('.js-direct-upload-input')
      input.click()
    }
  })

  document.addEventListener('change', event => {
    if (event.target.matches('.js-direct-upload-input') && event.target.value.length > 0) {
      document.querySelector('.js-direct-upload-submit').click()
    }
  })

  document.addEventListener('direct-upload:initialize', event => {
    const bar = document.querySelector('.js-direct-upload-progress')
    if (bar) {
      bar.classList.remove('hidden')
    }
  })

  document.addEventListener('direct-upload:progress', event => {
    const { progress } = event.detail
    const bar = document.querySelector('.js-direct-upload-progress .progress-bar')
    if (bar) {
      bar.style.width = `${progress}%`
    }
  })

  document.addEventListener('direct-upload:end', event => {
    const bar = document.querySelector('.js-direct-upload-progress .progress-bar')
    if (bar) {
      bar.style.width = '100%'
      bar.classList.add('progress-bar-success')
    }
  })

  document.addEventListener('direct-upload:error', event => {
    const bar = document.querySelector('.js-direct-upload-progress .progress-bar')
    if (bar) {
      bar.classList.add('progress-bar-danger')
    }
  })
}

// IE 11 polyfill
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector
}
