import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['cityField',
    'countryField',
    'descriptionField',
    'einField',
    'hideOnMerge',
    'logoField',
    'logoImage',
    'mergeButton',
    'nameField',
    'pinnedOrgs',
    'selectedOrg',
    'selectedOrganizations',
    'showOnMerge',
    'stateField',
    'street1Field',
    'street2Field',
    'tableData',
    'websiteUrlField',
    'zipField']

  static values = {
    logoUrl: String
  }

  tableDataTargetConnected () {
    $('[data-toggle="popover"]').popover();
  }

  select (event) {
    event.preventDefault()

    const dataset = event.target.dataset
    const id = dataset.id
    const name = dataset.name

    // If the org is already selected, do nothing
    if (this.selectedOrgTargets.find(target => target.dataset.id === event.target.dataset.id)) {
      return
    }

    const turboFrame = document.createElement('turbo-frame')
    turboFrame.setAttribute('id', `organization_${id}`)
    turboFrame.setAttribute('src', `/a/organization_entities/organization/${id}`)
    this.pinnedOrgsTarget.appendChild(turboFrame)
  }

  remove (event) {
    event.preventDefault()
    const id = event.currentTarget.dataset.id
    const selectedOrg = this.selectedOrgTargets.find(target => target.dataset.id === id)

    if (selectedOrg) {
      selectedOrg.remove()
    }
  }

  merge (event) {
    event.preventDefault()
    this.hideOnMergeTargets.forEach(t => t.classList.add('hidden'))
    this.showOnMergeTargets.forEach(t => t.classList.remove('hidden'))
  }

  addMore (event) {
    event.preventDefault()
    this.hideOnMergeTargets.forEach(t => t.classList.remove('hidden'))
    this.showOnMergeTargets.forEach(t => t.classList.add('hidden'))
  }

  copy (event) {
    event.preventDefault()
    const currTarget = event.currentTarget
    const eTarget = event.target
    const field = eTarget.dataset.field
    const dataValue = eTarget.dataset.value

    if (field !== 'source') {
      if (field === 'logo') {
        this.logoImageTarget.src = dataValue
        this.logoFieldTarget.value = dataValue
      } else {
        this[`${field}FieldTarget`].value = dataValue
      }

      this.clearColumnSelects(eTarget)

      // toggle gray background on copy
      if (currTarget.classList.contains('bg-gray-200')) {
        currTarget.classList.remove('bg-gray-200')
        this[`${field}FieldTarget`].value = ''

        if (field === 'logo') {
          this.logoImageTarget.src = this.logoUrlValue
          eTarget.style.backgroundColor = 'initial'
        }
      } else if (currTarget.innerText != '' || dataValue != '') {
        currTarget.classList.add('bg-gray-200')
        if (field === 'logo') {
          eTarget.style.backgroundColor = '#F5F5F5'
        }
      }
    }
  }

  clearColumnSelects (target) {
    const field = target.dataset.field

    this.tableDataTargets.forEach(t => {
      if (t.dataset.field === field && t !== target) {
        t.classList.remove('bg-gray-200')
        t.style.backgroundColor = 'initial'
      }
    })
  }
}
