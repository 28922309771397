import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['checkbox', 'submit']

  static classes = ['active', 'disabled']

  connect () {
    this.clicked()
  }

  clicked () {
    if (_.every(this.checkboxTargets, (p) => !p.checked)) {
      this.submitTarget.classList.remove(this.activeClass)
      this.submitTarget.classList.add(this.disabledClass)
    } else if (_.some(this.checkboxTargets, (p) => p.checked)) {
      this.submitTarget.classList.add(this.activeClass)
      this.submitTarget.classList.remove(this.disabledClass)
    }
  }
}
