import axios from 'axios'

export default function initPoller () {
  if (!window.Bf) {
    window.Bf = {}
  }
  window.Bf.poller = poller
  window.addEventListener('load', event => poller())
}

function poller () {
  const pollElement = document.querySelector('[data-poll-url]')

  if (pollElement) {
    let { pollUrl, pollInterval } = pollElement.dataset
    pollInterval = pollInterval || 3000

    const timeout = new Promise((resolve) => setTimeout(() => resolve(), pollInterval))
    timeout.then(() => getData(pollUrl)).then(() => poller())
  }
}

function getData (url) {
  axios.get(url, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/javascript'
    }
  }).then(response => Function('"use strict";' + response.data)())
}
