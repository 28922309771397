import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['title', 'user', 'organization', 'status', 'denyCheckbox']

  filter (event) {
    event.preventDefault()
    const id = 'filter-matches'
    const link = document.getElementById(id)
    const url = new URL(link.href)

    this.denyCheckboxTargets.forEach(t => { t.checked = false })

    url.searchParams.delete('title[]')
    url.searchParams.delete('user_id[]')
    url.searchParams.delete('organization[]')
    url.searchParams.delete('status')
    Array.from(this.titleTarget.options).filter(option => option.selected).forEach(option => url.searchParams.append('title[]', option.value))
    Array.from(this.userTarget.options).filter(option => option.selected).forEach(option => url.searchParams.append('user_id[]', option.value))
    Array.from(this.organizationTarget.options).filter(option => option.selected).forEach(option => url.searchParams.append('organization[]', option.value))
    this.statusTargets.filter(e => e.checked).forEach(e => url.searchParams.set('status', e.value))
    link.href = url.toString()

    link.click()
  }

}
