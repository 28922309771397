<template lang="pug">
  div
    .featured-funds-search-results-overlay(v-if="canViewSearchResults" @click="canViewSearchResults = false")
    h4 You can choose four Funds to feature on the Explore page of Bright Funds
    #featured-fund-search
      .well.light
        img.searching(src="../shared/images/waiting.gif" v-if="searching")
        input.form-control(
          type="text"
          placeholder="Search for a fund"
          @input="e => debouncedSearchFunds(e)"
          @focus="canViewSearchResults = true"
          :disabled="featuredFunds.length == 4"
        )

      ul.list-group.featured-fund-search-results(
        v-show="canViewSearchResults"
      )
        li.list-group-item(v-for="searchResult in searchResults" @click="addFeaturedFund(searchResult.id)")
          p.no-margin
            strong {{ searchResult.text }},
            span  created on {{ searchResult.created_at }}
            p Organizations: {{ searchResult.organizations.join(', ') }}

    draggable(v-model="featuredFunds" class="row" draggable=".featured-fund-card" @change="updateOrder")
      transition-group(name="flip-list")
        .col-md-3.featured-fund-card(v-for="companyFeaturedFund in featuredFunds" :key="companyFeaturedFund.id")
          FundCard(:fund="companyFeaturedFund.fund")
          a.btn.btn-danger(@click="removeFeaturedFund(companyFeaturedFund.id)") Remove
</template>

<script>
import { debounce } from 'lodash'
import draggable from 'vuedraggable'
import featuredFund from '../shared/services/featuredFund'
import FundCard from '../shared/components/FundCard'

export default {
  components: {
    FundCard,
    draggable
  },

  created() {
    this.debouncedSearchFunds = debounce(this.searchFunds, 300)
    featuredFund.fetchAll()
      .then(response => {
        this.featuredFunds = response.data.featured_funds
      })
  },

  data() {
    return {
      searching: false,
      canViewSearchResults: false,
      searchResults: [],
      featuredFunds: []
    }
  },

  methods: {
    searchFunds(e) {
      this.searching = true
      this.canViewSearchResults = true,

      featuredFund.search(e.target.value)
        .then(response => {
          this.searchResults = response.data.results
          this.searching = false
        })
    },

    addFeaturedFund(id) {
      featuredFund.add(id)
        .then(response => {
          this.searchResults = []
          this.featuredFunds = response.data.featured_funds
        })
    },

    removeFeaturedFund(id) {
      featuredFund.remove(id)
        .then(response => {
          this.featuredFunds = response.data.featured_funds
        })
    },

    updateOrder() {
      let sortOrders = this.featuredFunds.map((companyFeaturedFund, index) => { return { id: companyFeaturedFund.id, sortOrder: index } })

      featuredFund.sort(sortOrders)
    }
  }
}
</script>
