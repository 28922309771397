<template lang="pug">
  div
    i.fa.fa-arrows
    span  {{campaign.name}}
</template>

<script>
export default {
  name: 'CampaignSortOrderNameplate',
  props: {
    campaign: {
      type: Object,
      default: () => {}
    }
  }
}
</script>