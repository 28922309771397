<template lang="pug">
  div(class="col-6")
    draggable(class="list-group" ghost-class="ghost" v-model="campaigns" @change="updateOrder")
      transition-group
        .campaign-order-plate(v-for="campaign in campaigns" :key="campaign.id")
          CampaignSortOrderNameplate(:campaign="campaign" class="list-group-item")
    button(class="btn btn-primary" @click="sendUpdatedOrder") Submit
    b-alert(:show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged") Campaign Ordering Successful!
</template>

<script>
import draggable from 'vuedraggable'
import campaignSortOrder from '../shared/services/campaignSortOrder'
import CampaignSortOrderNameplate from '../shared/components/CampaignSortOrderNameplate'
export default {
  components: {
    CampaignSortOrderNameplate,
    draggable
  },
  created() {
    campaignSortOrder.fetchAll()
      .then(response => {
        this.campaigns = response.data.campaigns
      })
  },
  data() {
    return {
      campaigns: [],
      sortOrder: [],
      dismissSecs: 2,
      dismissCountDown: 0
    }
  },
  methods: {
    updateOrder() {
      this.sortOrder = this.campaigns.map((campaign, index) => { return { id: campaign.id, order: index } })
     },
    sendUpdatedOrder() {
      campaignSortOrder.sort(this.sortOrder)
        .then(this.showAlert)
    },
    countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>

<style scoped>
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .campaign-order-plate {
    transition: transform .5s;
  }
</style>