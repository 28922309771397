import Vue from 'vue'
import FeaturedFunds from './featured_funds/FeaturedFunds'
import { i18n } from './gifts/config/translations.js'
import axios from 'axios'

Vue.filter('pluralize', function (word, count) {
  if (!word) return ''

  if (count > 1) {
    return word + 's'
  } else {
    return word
  }
})

Vue.filter('truncate', function (text, length) {
  if (!text) return ''

  return text.slice(0, length)
})

export default function featuredFunds () {
  const appContainer = document.getElementById('vue-featured-funds')

  // configure axios
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  axios.defaults.headers.common['X-CSRF-Token'] = token

  if (appContainer) {
    const el = appContainer.appendChild(document.createElement('app'))

    /* eslint-disable no-unused-vars */
    const app = new Vue({
      i18n,
      el,
      render: h => h(FeaturedFunds)
    })
    /* eslint-enable no-unused-vars */
  }
}
