import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'error', 'button']

  connect () {
    console.log('connected')
    this.errorTarget.classList.add('d-none')
  }

  checkSpace () {
    const whitespaceChars = [' ', '\t', '\n']
    const urlInput = this.inputTarget.value
    const whiteSpaceError = whitespaceChars.some(char => urlInput.includes(char))

    if (whiteSpaceError || !urlInput.includes("http")) {
      this.errorTarget.classList.remove('d-none')
      this.buttonTarget.classList.add('btn-disabled')
    } else {
      this.errorTarget.classList.add('d-none')
      this.buttonTarget.classList.remove('btn-disabled')
    }
  }
}
