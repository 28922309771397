import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['orgName', 'designationForm']

  connect () {
    this.checkText()
  }

  checkText () {
    const orgFundName = this.orgNameTarget.value
    const isOrg = orgFundName.includes('organizations/')
    if ((orgFundName.length > 0) && isOrg) {
      this.designationFormTarget.classList.remove('hidden')
    } else {
      this.designationFormTarget.classList.add('hidden')
    }
  }
}
