import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'outsideButton']

  connect () {
    if (this.outsideButtonTarget.dataset.company === 'fmc') {
      this.outsideButtonTarget.classList.add('btn-disabled')
    }
  }

  handleDisability () {
    const checked = this.checkboxTargets.map(x => x.checked).includes(true)
    if (checked) {
      this.outsideButtonTarget.classList.remove('btn-disabled')
    } else {
      this.outsideButtonTarget.classList.add('btn-disabled')
    }
  }
}
