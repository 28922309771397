import _ from 'lodash'
import featuredFunds from '../featured_funds.js'
import initPoller from '../poller.js'
import initDirectUpload from '../direct_upload.js'
import campaignSortOrder from '../campaign_sort_order.js'
import charts from '../charts.js'
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Turbo from '@hotwired/turbo'


/* Stimulus setup */
window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
Stimulus.debug = process.env.NODE_ENV === 'development'

/* Page show custom event */
window.addEventListener('pageshow', event => {
    Stimulus.controllers.forEach(controller => {
        if (typeof controller.pageShow === 'function') {
        controller.pageShow(event.persisted)
        }
    })
})

/* Disable turbo drive for now as it requires a lot of QA */
Turbo.session.drive = false

initPoller()
initDirectUpload()
featuredFunds()
campaignSortOrder()
