import Vue from 'vue/dist/vue.esm.js'
import BfChart from './shared/components/BfChart.vue'

document.addEventListener('DOMContentLoaded', () => {
  if (!document.getElementById('charts')) { return }

  /* eslint-disable no-new */
  new Vue({
    el: '#charts',
    components: {
      'bf-chart': BfChart
    },
    data: {
      message: 'hello world!'
    }
  })
})
