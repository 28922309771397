import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['expiration', 'days', 'expHelper', 'periodic']

  connect () {
    this.updateDays()
    this.setMin()
  }

  daysTilExp () {
    const date = new Date(this.expirationTarget.value)
    const today = new Date()
    // difference in days
    const diff = Math.ceil((date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24))
    return diff
  }

  updateDays () {
    const days = this.daysTilExp()

    this.dateHelperText(days)
    this.togglePeriodic(days)
  }

  // Display time until expiration
  dateHelperText (days) {
    let ending
    if ((days > 0) && (days < 31)) {
      this.expHelperTarget.classList.remove('d-none')
      ending = days === 1 ? 'day.' : 'days.'
      this.daysTarget.innerHTML = days + ' ' + ending
    } else if (days > 31) {
      this.expHelperTarget.classList.remove('d-none')
      const months = Math.floor(days / 30)
      ending = months === 1 ? 'month.' : 'months.'
      this.daysTarget.innerHTML = months + ' ' + ending
    } else {
      this.expHelperTarget.classList.add('d-none')
    }
  }

  // Remove periodic emails if less than 31 days
  togglePeriodic (days) {
    if ((days < 31) || isNaN(days)) {
      this.periodicTarget.classList.add('d-none')
    } else {
      this.periodicTarget.classList.remove('d-none')
    }
  }

  // Set lower limit on datepicker to today
  setMin () {
    const today = new Date()
    const todayString = today.toISOString().split('T')[0]
    this.expirationTarget.setAttribute('min', todayString)
  }
}
