import Vue from 'vue'
import { BAlert } from 'bootstrap-vue'
import CampaignSortOrder from './campaign_sort_order/CampaignSortOrder'
import { i18n } from './gifts/config/translations.js'
import axios from 'axios'

export default function campaignSortOrder () {
  const appContainer = document.getElementById('vue-campaign-sort-order')

  // configure axios
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  axios.defaults.headers.common['X-CSRF-Token'] = token

  if (appContainer) {
    const el = appContainer.appendChild(document.createElement('app'))
    Vue.component('BAlert', BAlert)
    Vue.directive('b-alert', BAlert)
    /* eslint-disable no-unused-vars */
    const app = new Vue({
      i18n,
      el,
      render: h => h(CampaignSortOrder)
    })
    /* eslint-enable no-unused-vars */
  }
}
