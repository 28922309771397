import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'textInput', 'zipInput', 'radiusInput', 'stateInput', 'cityInput', 'labelInput', 'filtersInput',
    'causeArea', 'allAccess', 'causeAreaDropdown', 'causeAreaDropdownLabel', 'selectedcauseArea', 'updateButton', 'clearAll', 'select']

  connect () {
    this.checkboxClicked()
    this.toggleReset()
  }

  clearAllFilters (event) {
    event.stopPropagation()

    const filter = this.filtersInputTargets.filter(filter => filter.checked)
    this.causeAreaTargets.forEach(causeArea => (causeArea.checked = false))
    if (filter[0].value === 'zip_miles') {
      this.zipInputTarget.value = ''
      this.radiusInputTarget.value = ''
    } else if (filter[0].value === 'city_state') {
      this.stateInputTarget.value = ''
      this.cityInputTarget.value = ''
    }
    this.radioChanged()
  }

  radioChanged () {
    if (this.textInputTarget.value.length > 0) {
      this.formTarget.submit()
    }
  }

  radiusChanged () {
    if (this.zipInputTarget.value === '' && this.radiusInputTarget.value > 0) {
      alert('Please enter Zip code')
      this.radiusInputTarget.value = 0
    } else {
      this.manageUpdateButton()
    }
  }

  zipChagned () {
    const zip = this.zipInputTarget.value
    if (zip.length > 5) {
      alert('It will be an invalid ZIP Code')
      this.zipInputTarget.value = zip.substr(0, 5)
    } else if (zip.length < 2) {
      this.radiusInputTarget.value = 0
    }
    this.manageUpdateButton()
  }

  stateChanged (event) {
    event.stopPropagation()

    this.cityInputTarget.value = ''
    this.manageUpdateButton()
  }

  cityChanged () {
    this.manageUpdateButton()
  }

  selectChanged () {
    if (this.textInputTarget.value.length > 0) {
      this.formTarget.submit()
    }
  }

  URLParams () {
    const queryString = window.location.search.replaceAll('%5B%5D', '')
    const urlParams = new URLSearchParams(queryString)
    urlParams.delete('subject_codes')
    return urlParams
  }

  loadZipFilter () {
    const urlParams = this.URLParams()

    urlParams.set('location_filter', 'zip_miles')
    urlParams.delete('state')
    urlParams.delete('city')
    window.location = window.location.pathname + '?' + urlParams.toString()
  }

  loadCityFilter () {
    const urlParams = this.URLParams()

    urlParams.set('location_filter', 'city_state')
    urlParams.delete('zip')
    urlParams.delete('radius')
    window.location = window.location.pathname + '?' + urlParams.toString()
  }

  getSelectedCauseAreas () {
    return this.causeAreaTargets.filter(causeArea => causeArea.checked)
  }

  checkRadioState () {
    this.allAccessTarget.checked === true ? this.disableDropdown() : this.enableDropdown()
    this.manageSubmitButton(this.getSelectedCauseAreas().length)
  }

  updateLabel (causeAreas) {
    this.causeAreaDropdownLabelTarget.innerHTML = this.createLabel(causeAreas)
  }

  createLabel (causeAreas) {
    const len = causeAreas.length
    switch (len) {
      case 0:
        return 'Select Cause Areas '
      case 1:
        return causeAreas[0].dataset.causeAreaName + ' '
      default:
        return len + ' Cause Areas Selected '
    }
  }

  menuClicked (event) {
    event.stopPropagation()
  }

  checkboxClicked () {
    const selectedCauseAreas = this.getSelectedCauseAreas()
    this.updateLabel(selectedCauseAreas)
    this.manageUpdateButton()
  }

  countFilters () {
    const filter = this.filtersInputTargets.filter(filter => filter.checked)
    const selectedCauseAreas = this.getSelectedCauseAreas().length

    if (filter[0].value === 'zip_miles') {
      const zipLength = this.zipInputTarget.value.length
      const radiusLength = Number(this.radiusInputTarget.value)
      this.count = zipLength + selectedCauseAreas + radiusLength
    } else if (filter[0].value === 'city_state') {
      const stateCount = this.stateInputTarget.value.length
      const cityCount = this.cityInputTarget.value.length
      this.count = cityCount + stateCount + selectedCauseAreas
    }

    return this.count
  }

  toggleReset () {
    if (this.countFilters() > 0) {
      this.clearAllTarget.classList.remove('d-none')
    } else {
      this.clearAllTarget.classList.add('d-none')
    }
  }

  manageUpdateButton () {
    if (this.countFilters() === 0) {
      this.updateButtonTarget.disabled = true
      this.updateButtonTarget.classList.add('btn-disabled')
      this.clearAllTarget.classList.add('d-none')
    } else {
      this.updateButtonTarget.disabled = false
      this.updateButtonTarget.classList.remove('btn-disabled')
    }
  }
}
