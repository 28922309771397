import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['label', 'selectedItems', 'selectBox']
  static values = { title: String }

  connect () {
    this.setSelected()
  }

  change () {
    this.setSelectedLabel()
    this.setSelected()
  }

  clickItem (event) {
    event.stopPropagation()
  }

  removeSelected (event) {
    event.stopPropagation()
    event.target.remove()
  }

  selectedOptions () {
    const options = _.filter(this.selectBoxTarget, (o) => o.selected)
    return _.map(options, (opt) => opt.text)
  }

  setSelectedLabel () {
    const options = this.selectedOptions()

    if (options.length === 0) {
      this.labelTarget.innerHTML = this.titleValue
    } else if (options.length === 1) {
      this.labelTarget.innerHTML = options[0]
    } else {
      this.labelTarget.innerHTML = `${options[0]} +${options.length - 1}`
    }
  }

  setSelected () {
    const options = this.selectedOptions()
    const selectedSpans = _.reduce(options, (spans, opt) => {
      spans += `<span class="badge margin-5" data-action="click->multiselect#removeSelected">${opt} x</span>`
      return spans
    }, '')

    this.selectedItemsTarget.innerHTML = selectedSpans
  }
}
