import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['div', 'plusIcon', 'negativeIcon']

  switchIcon () {
    if (this.divTarget.classList.contains('in')) {
      this.negativeIconTarget.classList.add('hidden')
      this.plusIconTarget.classList.remove('hidden')
    } else {
      this.plusIconTarget.classList.add('hidden')
      this.negativeIconTarget.classList.remove('hidden')
    }
  }
}
