<template lang="pug">
  .fund-card-link
    .fund-card
      .fund-card__image
        img(:src="fund.image")
        .fund-card__certified(v-if="fund.certified")
          img(src="../images/banner-vettedByBrightFunds.svg")
        .fund-card__view-tag &rarr;
      .fund-card__main
        .fund-card__org-count {{ fund.fund_organizations.length }} {{ 'nonprofit' | pluralize(fund.fund_organizations.length) }}
        .fund-card__title {{ fund.decorated_name }}
        .fund-card__description {{ fund.description | truncate(100) }}
    div(style="margin-top: 60px;" v-if="fund.certified")
    .fund-card-footer(v-else)
      .fund-card-footer__author
        .fund-card-footer__image
          .img-circle.profile-header-img.company-fund(style="background-image:url(#{fund.fund_creator_image})")
        .fund-card-footer__copy created by {{ fund.creator_name }}
</template>

<script>
export default {
  name: 'FundCard',

  props: {
    fund: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
