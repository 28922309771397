import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['status', 'statusdropdown', 'statusDropdownLabel', 'labelTag']

  connect () {
    this.checkboxClicked()
  }

  getSelectedStatuses () {
    return this.statusTargets.filter(status => status.checked)
  }

  menuClicked (event) {
    event.stopPropagation()
  }

  updateLabel (statuses) {
    this.statusDropdownLabelTarget.classList.add("dropdown-div")
    this.statusDropdownLabelTarget.innerHTML = this.createLabel(statuses)
  }

  createLabel (statuses) {
    let len = 0
    let label = this.labelTagTarget.innerHTML.toLowerCase() + ' selected'

    if (statuses !== undefined) {
      len = statuses.length
    }
    switch (len) {
      case 0:
        return 'Select Any'
      case 1:
        return statuses[0].dataset.statusName + ' '
      default:
        return len + ' ' + label
    }
  }

  checkboxClicked () {
    const selectedStatuses = this.getSelectedStatuses()
    this.updateLabel(selectedStatuses)
  }
}
