import axios from 'axios'

export default {
  fetchAll () {
    return axios.get('/a/settings/featured_funds.json')
      .catch((err) => { window.alert(err) })
  },
  search (query) {
    return axios.get(`/funds/search?search=${query}`)
      .catch((err) => { window.alert(err) })
  },
  add (id, sortOrder) {
    return axios.post('/a/settings/featured_funds.json', { id: id })
      .catch((err) => { window.alert(err.response.data.message) })
  },
  remove (id) {
    return axios.delete(`/a/settings/featured_funds/${id}`)
      .catch((err) => { window.alert(err) })
  },
  sort (sortOrders) {
    return axios.post('/a/settings/featured_funds/sort', { sort_orders: sortOrders })
      .catch((err) => { window.alert(err) })
  }
}
