import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['addressField', 'remoteCheckbox']

  connect () {
    this.toggleRequiredAttr()
  }
  toggleRequiredAttr () {    
    if (this.remoteCheckboxTarget.checked) {
      this.addressFieldTarget.removeAttribute('required')
    } else {
      this.addressFieldTarget.setAttribute('required', 'required')
    }
  }
}
