import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['dateInput', 'submitButtom']

  initialize () {
    this.regEx = /^\d{4}-\d{2}-\d{2}$/
  }

  appendErrorMessage (parentElement) {
    const ele = document.createElement('span')
    ele.classList.add('color-brand-danger', 'margin-10-top', 'error-message')
    ele.innerHTML = 'Invalid date'
    parentElement.appendChild(ele)
  }

  onDateChange (event) {
    event.stopPropagation()

    const validDates = this.dateInputTargets.filter(input => (
      this.regEx.test(input.value) && new Date(input.value).toString() !== 'Invalid Date'
    ))
    const invalidDates = this.dateInputTargets.filter(input => (
      !this.regEx.test(input.value) || new Date(input.value).toString() === 'Invalid Date'
    ))

    if (invalidDates.length > 0) {
      this.submitButtomTarget.classList.add('btn-disabled')
      invalidDates.map(date => {
        const parent = document.getElementById(date.id).parentNode
        const error = parent.querySelector('.error-message')
        if (parent.childElementCount >= 1 && !error) {
          this.appendErrorMessage(parent)
        }
      })
    }

    if (validDates.length > 0) {
      validDates.map(date => {
        const parent = document.getElementById(date.id).parentNode
        if (parent.childElementCount > 1) {
          parent.removeChild(parent.lastChild)
        }
        if (invalidDates.length === 0) {
          this.submitButtomTarget.classList.remove('btn-disabled')
        }
      })
    }
  }
}
