import axios from 'axios'

export default {
  fetchAll () {
    return axios.get('/a/settings/campaigns/listed.json')
      .catch((err) => { window.alert(err) })
  },
  sort (sortOrders) {
    return axios.post('/a/settings/campaigns/sort_campaigns', { sort_orders: sortOrders })
      .catch((err) => { window.alert(err) })
  }
}