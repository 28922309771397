import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['grantQuestion', 'grantQuestionContainer']

  typeSelected (event) {
    const id = `question-type-${event.target.dataset.index}`
    const link = document.getElementById(id)
    const textValue = document.getElementById(`question_description_trix_${event.target.dataset.index}`).value
    const title = document.getElementById(`question-title-${event.target.dataset.index}`).value
    const grantQuestionId = document.getElementById(`grant-question-id-${event.target.dataset.index}`).value
    const url = new URL(link.href)
    url.searchParams.set('question_type', event.target.value)
    url.searchParams.set('description', textValue)
    url.searchParams.set('title', title)
    url.searchParams.set('grant_question_id', grantQuestionId)
    link.href = url.toString()
    link.click()
  }
}
