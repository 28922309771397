import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['country', 'allAccess', 'validationMessage', 'countryDropdown', 'countryDropdownLabel', 'selectedCountry', 'submit']

  connect () {
    this.checkRadioState()
  }

  getSelectedCountries () {
    return this.countryTargets.filter(country => country.checked)
  }

  checkRadioState () {
    this.allAccessTarget.checked === true ? this.disableDropdown() : this.enableDropdown()
    this.manageSubmitButton(this.getSelectedCountries().length)
  }

  disableDropdown () {
    this.countryDropdownTarget.classList.add('d-none')
    this.countryDropdownTarget.classList.add('btn-disabled')
    this.countryDropdownTarget.disabled = true
    this.countryDropdownLabelTarget.innerHTML = 'All Countries '
  }

  enableDropdown () {
    this.countryDropdownTarget.classList.remove('d-none')
    this.countryDropdownTarget.classList.remove('btn-disabled')
    this.countryDropdownTarget.disabled = false
    this.updateLabel(this.getSelectedCountries())
  }

  manageSubmitButton (count) {
    if ((count === 0) && (this.allAccessTarget.checked === false)) {
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('btn-disabled')
      this.validationMessageTarget.style.visibility = 'visible'
    } else {
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove('btn-disabled')
      this.validationMessageTarget.style.visibility = 'hidden'
    }
  }

  updateLabel (countries) {
    this.countryDropdownLabelTarget.innerHTML = this.createLabel(countries)
  }

  createLabel (countries) {
    const len = countries.length
    switch (len) {
      case 0:
        return 'Select Countries '
      case 1:
        return countries[0].dataset.countryName + ' '
      default:
        return len + ' Countries Selected '
    }
  }

  menuClicked (event) {
    event.stopPropagation()
  }

  checkboxClicked () {
    const selectedCountries = this.getSelectedCountries()
    this.updateLabel(selectedCountries)
    this.manageSubmitButton(selectedCountries.length)
  }
}
