import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'countChecked', 'mergeButton', 'selectedNom']

  connect () {
    this.countChecked()
  }

  select (event) {
    event.preventDefault()
    if (event.target.checked) {
      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = 'nominations[]'
      hiddenInput.id = `selected_nomination_${event.target.value}`
      hiddenInput.value = event.target.value
      document.getElementById('merge-nominations-form').appendChild(hiddenInput)
    } else {
      const uncheckedInput = document.getElementById(`selected_nomination_${event.target.value}`)
      document.getElementById('merge-nominations-form').removeChild(uncheckedInput)
    }

    this.countChecked()
  }

  checkboxTargetConnected (event) {
    const selectedHiddenInput = document.getElementById(`selected_nomination_${event.value}`)
    if (selectedHiddenInput) {
      event.checked = true
    }
  }

  countChecked () {
    const hiddenForm = document.getElementById('merge-nominations-form')
    const count = hiddenForm.querySelectorAll('input[name="nominations[]"]').length

    this.handleMergeButton(count)
    this.countCheckedTarget.innerHTML = count + ' selected'
  }

  handleMergeButton (count) {
    if (count > 1) {
      this.mergeButtonTarget.disabled = false
      this.mergeButtonTarget.classList.remove('btn-disabled')
    } else {
      this.mergeButtonTarget.disabled = true
      this.mergeButtonTarget.classList.add('btn-disabled')
    }
  }
}
