import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['default', 'customize']

  toggleTargets (event) {
    let targets
    let nontargets

    switch (event.params.location) {
      case 'default':
        targets = this.defaultTargets
        nontargets = this.customizeTargets
        break
      case 'customize':
        targets = this.customizeTargets
        nontargets = this.defaultTargets
        break
    }

    // const location = event.params.location
    // const targets = document.querySelectorAll(`[data-toggle="${location}"]`)

    targets.forEach((target) => {
      target.classList.toggle('hidden')
    })
    nontargets.forEach((target) => {
      target.classList.add('hidden')
    })
  }
}
