import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['selectAll', 'checkboxItem']

  connect () {
    this.syncCheckBoxes()
  }

  selectAllClicked () {
    _.each(this.checkboxItemTargets, (p) => { p.checked = this.selectAllTarget.checked })
  }

  checkboxItemClicked () {
    this.syncCheckBoxes()
  }

  syncCheckBoxes () {
    if (_.every(this.checkboxItemTargets, (p) => p.checked)) {
      this.selectAllTarget.checked = true
    } else if (_.some(this.checkboxItemTargets, (p) => !p.checked)) {
      this.selectAllTarget.checked = false
    }
  }
}
